// From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/importKey#subjectpublickeyinfo_import
export function stringToArrayBuffer(value: string): ArrayBuffer {
  const buffer = new ArrayBuffer(value.length);
  const binary = new Uint8Array(buffer);
  for (let i = 0, strLen = value.length; i < strLen; i++) {
    binary[i] = value.charCodeAt(i);
  }
  return binary;
}

export function arrayBufferToString(buffer: ArrayBuffer): string {
  return String.fromCharCode(...new Uint8Array(buffer));
}

import { Optional } from '../utils/Optional';

interface DomainFields {
  id: string;
  name?: Optional<string>;
  description?: Optional<string>;
  topic: string;
}

export class Domain implements DomainFields {
  id: string;
  name?: Optional<string>;
  description?: Optional<string>;
  topic: string;

  constructor({ id, name, description, topic }: DomainFields) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.topic = topic;
  }
}

/**
 * Visible for testing.
 */
export const mockDomain = new Domain({
  id: 'test',
  name: 'Test Domain',
  description: 'A domain that is used in tests.',
  topic: '/test/',
});

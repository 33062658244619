/* eslint-disable @typescript-eslint/camelcase */
import { isDefined } from '../../utils/Utils';
import { EncryptionService } from '../encryption/EncryptionService';
import { PublishUpdateParameters } from '../things/models/PublishUpdateParameters';
import { ThingStateFull } from '../things/models/ThingStateFull';
import { ThingUnit } from '../things/models/ThingUnit';
import { ThingsMQTTService } from '../things/ThingsMQTTService';
import { AddUnitParameters } from './models/AddUnitParameters';
import { RemoveUnitParameters } from './models/RemoveUnitParameters';
import { UpdateUnitParameters } from './models/UpdateUnitParameters';
import { UnitsServiceInterface } from './UnitsServiceInterface';

class UnitsServiceImplementation implements UnitsServiceInterface {
  async addUnit({
    thing,
    name,
    productType,
    ipAddress,
    username,
    password,
  }: AddUnitParameters): Promise<void> {
    const state: ThingStateFull = {};

    const unit = thing.units?.find(unit => unit.ipAddress === ipAddress);
    if (thing.isNetworkedThingsSupported) {
      const encryptedUsername = username
        ? await EncryptionService.encrypt(
            thing.thingName,
            username,
            thing.thingName
          )
        : undefined;

      const encryptedPassword = password
        ? await EncryptionService.encrypt(
            thing.thingName,
            password,
            thing.thingName
          )
        : undefined;

      state.unit = {
        label: name,
        product_type: productType,
        ip_address: ipAddress,
        username: encryptedUsername,
        password: encryptedPassword,
      };
    } else if (isDefined(unit)) {
      state[unit.id] = {
        label: name,
        product_type: productType,
        ip_address: ipAddress,
        installed: true,
      };
    }

    ThingsMQTTService.publishUpdate({
      domainPath: thing.domain.topic,
      thingId: thing.thingName,
      state,
      useReportedState: thing.isNetworkedThingsSupported === false,
    });
  }

  removeUnit({ unit, thing }: RemoveUnitParameters): void {
    const state: ThingStateFull = {};

    if (thing.isNetworkedThingsSupported) {
      state.unit = {
        id_number: unit.idNumber,
        product_type: unit.productType,
        delete: true,
      };
    } else {
      state[unit.id] = {
        installed: false,
        label: '',
      };
    }

    ThingsMQTTService.publishUpdate({
      domainPath: thing.domain.topic,
      thingId: thing.thingName,
      state,
      useReportedState: thing.isNetworkedThingsSupported === false,
    });
  }

  async updateUnit({
    thing,
    newUnit,
    oldUnit,
  }: UpdateUnitParameters): Promise<void> {
    const defaultState: ThingStateFull = {};
    const unit: ThingUnit = {};

    const desiredState: ThingStateFull = {};
    const desiredUnit: ThingUnit = {};
    let useDesiredState = false;

    if (thing.isNetworkedThingsSupported) {
      unit.id_number = oldUnit.idNumber;
      unit.product_type = oldUnit.productType;

      const encryptedUsername = newUnit.username
        ? await EncryptionService.encrypt(
            thing.thingName,
            newUnit.username,
            thing.thingName
          )
        : undefined;
      unit.username = encryptedUsername;

      const encryptedPassword = newUnit.password
        ? await EncryptionService.encrypt(
            thing.thingName,
            newUnit.password,
            thing.thingName
          )
        : undefined;
      unit.password = encryptedPassword;

      if (newUnit.name !== oldUnit.name) {
        unit.label = newUnit.name;
      }

      if (newUnit.ipAddress !== oldUnit.ipAddress) {
        unit.ip_address = newUnit.ipAddress;
      }

      defaultState.unit = unit;
    } else {
      if (newUnit.name !== oldUnit.name) {
        unit.label = newUnit.name;
      }

      // ? The unit IP address needs to be set in desired state.
      if (newUnit.ipAddress !== oldUnit.ipAddress) {
        desiredUnit.ip_address = newUnit.ipAddress;
        useDesiredState = true;
      }

      defaultState[newUnit.id] = unit;
      desiredState[newUnit.id] = desiredUnit;
    }

    const publishUpdateParameters: Omit<
      PublishUpdateParameters,
      'useReportedState'
    > = {
      domainPath: thing.domain.topic,
      thingId: thing.thingName,
      state: defaultState,
    };
    if (useDesiredState) {
      ThingsMQTTService.publishUpdate({
        ...publishUpdateParameters,
        state: desiredState,
        useReportedState: false,
      });
      ThingsMQTTService.publishUpdate({
        ...publishUpdateParameters,
        useReportedState: true,
      });
    } else {
      ThingsMQTTService.publishUpdate({
        ...publishUpdateParameters,
        useReportedState: thing.isNetworkedThingsSupported === false,
      });
    }
  }
}

export const UnitsService = new UnitsServiceImplementation();

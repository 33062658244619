export enum DefaultValueUnit {
  MWh = 'MWh',
  KWh = 'kWh',
  KW = 'kW',
  W = 'W',
  C = 'C',
  M3s = 'm3/s',
  KWM3s = 'kW/m3/s',
  Ls = 'l/s',
  Pa = 'Pa',
}

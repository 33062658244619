import { AlarmsService } from '../services/alarms/AlarmsService';
import { AlarmPrio } from '../services/alarms/models/AlarmPrio';
import { ConnectionStatus } from '../services/things/models/ConnectionStatus';
import { ThingUnit } from '../services/things/models/ThingUnit';
import { ThingUnitAlarm } from '../services/things/models/ThingUnitAlarm';
import { Optional } from '../utils/Optional';
import { isDefined, isEmpty } from '../utils/Utils';
import { DefaultValueUnit } from './DefaultValueUnit';
import { getProductType, ProductType } from './ProductType';
import { UnitFields } from './UnitFields';
import { UnitInterface } from './UnitInterface';
import { ValueWithUnit } from './ValueWithUnit';
import { toNumber } from 'lodash-es';

function sumConverter(
  a: Optional<number>,
  b: Optional<number>,
  convertB?: (value: number) => number
): number | undefined {
  if (isDefined(a) && isDefined(b)) {
    const convertedB = convertB ? convertB(b) : b;
    return a + convertedB;
  }
  return undefined;
}

function createValueWithUnit(
  valueWithUnit: Optional<ValueWithUnit<number, string>>
): ValueWithUnit<number, string> | undefined {
  return isDefined(valueWithUnit?.value) && valueWithUnit?.unit
    ? new ValueWithUnit(valueWithUnit)
    : undefined;
}

export class Unit implements UnitFields, UnitInterface {
  id: string;

  alarms?: Optional<ThingUnitAlarm[]>;
  connectionStatus?: Optional<ConnectionStatus>;
  extPort?: Optional<number>;
  installed?: Optional<boolean>;
  ipAddress?: Optional<string>;
  name?: Optional<string>;
  operationStatus?: Optional<number>;
  password?: Optional<string>;
  productType?: Optional<string>;
  proxy?: Optional<string>;
  proxyTTL?: Optional<number>;
  proxyURL?: Optional<string>;
  timestamp?: Optional<number>;
  type?: Optional<string>;
  user?: Optional<string>;
  username?: Optional<string>;
  version?: Optional<string>;

  ahuEnergyConsumption?: Optional<ValueWithUnit<number, string>>;
  coolPowerRecovery?: Optional<ValueWithUnit<number, string>>;
  extractAir?: Optional<ValueWithUnit<number, string>>;
  extractAirDuct?: Optional<ValueWithUnit<number, string>>;
  extractAirTemperature?: Optional<ValueWithUnit<number, string>>;
  heatCoolEnergyConsumption?: Optional<ValueWithUnit<number, string>>;
  heatPowerRecovery?: Optional<ValueWithUnit<number, string>>;
  hx?: Optional<ValueWithUnit<number, string>>;
  outdoorAirTemperature?: Optional<ValueWithUnit<number, string>>;
  sfp?: Optional<ValueWithUnit<number, string>>;
  supplyAir?: Optional<ValueWithUnit<number, string>>;
  supplyAirDuct?: Optional<ValueWithUnit<number, string>>;
  supplyAirTemperature?: Optional<ValueWithUnit<number, string>>;

  constructor({
    id,

    alarms,
    connectionStatus,
    extPort,
    installed,
    ipAddress,
    name,
    operationStatus,
    password,
    productType,
    proxy,
    proxyTTL = 1800,
    proxyURL,
    timestamp,
    type,
    user,
    username,
    version,

    ahuEnergyConsumption,
    coolPowerRecovery,
    extractAir,
    extractAirDuct,
    extractAirTemperature,
    heatCoolEnergyConsumption,
    heatPowerRecovery,
    hx,
    outdoorAirTemperature,
    sfp,
    supplyAir,
    supplyAirDuct,
    supplyAirTemperature,
  }: UnitFields) {
    this.id = id;

    this.alarms = alarms ? [...alarms] : undefined;
    this.connectionStatus = connectionStatus;
    this.extPort = extPort;
    this.installed = installed;
    this.ipAddress = ipAddress;
    this.name = name;
    this.operationStatus = operationStatus;
    this.password = password;
    this.productType = productType;
    this.proxy = proxy;
    this.proxyTTL = proxyTTL;
    this.proxyURL = proxyURL;
    this.timestamp = timestamp;
    this.type = type;
    this.user = user;
    this.username = username;
    this.version = version;

    this.ahuEnergyConsumption = createValueWithUnit(ahuEnergyConsumption);
    this.coolPowerRecovery = createValueWithUnit(coolPowerRecovery);
    this.extractAir = createValueWithUnit(extractAir);
    this.extractAirDuct = createValueWithUnit(extractAirDuct);
    this.extractAirTemperature = createValueWithUnit(extractAirTemperature);
    this.heatCoolEnergyConsumption = createValueWithUnit(
      heatCoolEnergyConsumption
    );
    this.heatPowerRecovery = createValueWithUnit(heatPowerRecovery);
    this.hx = createValueWithUnit(hx);
    this.outdoorAirTemperature = createValueWithUnit(outdoorAirTemperature);
    this.sfp = createValueWithUnit(sfp);
    this.supplyAir = createValueWithUnit(supplyAir);
    this.supplyAirDuct = createValueWithUnit(supplyAirDuct);
    this.supplyAirTemperature = createValueWithUnit(supplyAirTemperature);
  }

  get idNumber(): number {
    const id = this.id.slice('unit'.length);
    return parseInt(id);
  }

  get isAlarmsSupported(): boolean {
    return (
      this.productType === ProductType.GoldE ||
      this.productType === ProductType.GoldF
    );
  }

  get hasAlarms(): boolean {
    if (isDefined(this.alarms)) {
      return this.alarms?.length > 0;
    }
    return false;
  }

  get hasParameters(): boolean {
    return [
      this.version,
      this.ahuEnergyConsumption,
      this.coolPowerRecovery,
      this.extractAir,
      this.extractAirDuct,
      this.extractAirTemperature,
      this.heatCoolEnergyConsumption,
      this.heatPowerRecovery,
      this.hx,
      this.outdoorAirTemperature,
      this.sfp,
      this.supplyAir,
      this.supplyAirDuct,
      this.supplyAirTemperature,
    ].some(isDefined);
  }

  get isParametersSupported(): boolean {
    return (
      this.productType === ProductType.GoldE ||
      this.productType === ProductType.GoldF
    );
  }

  get isUsernamePasswordSupported(): boolean {
    return (
      this.productType === ProductType.GoldE ||
      this.productType === ProductType.GoldF
    );
  }

  get isConnectable(): boolean {
    return this.isOnline && !isEmpty(this.id) && !isEmpty(this.ipAddress);
  }

  get isInstalled(): boolean {
    return this.installed == true;
  }

  get isIotOnly(): boolean {
    return this.connectionStatus === ConnectionStatus.IoTOnly;
  }

  get isOffline(): boolean {
    return (
      this.connectionStatus === ConnectionStatus.Offline ||
      !isDefined(this.connectionStatus)
    );
  }

  get isOnline(): boolean {
    return (
      this.connectionStatus === ConnectionStatus.Online ||
      this.connectionStatus === ConnectionStatus.IoTOnly
    );
  }

  get productImageURL(): string {
    if (isDefined(this.productType)) {
      return `/img/units/${encodeURI(this.productType)}.png`;
    }

    return '';
  }

  get sortedAlarms(): ThingUnitAlarm[] {
    return AlarmsService.sort(this.alarms ?? []);
  }

  static empty(): Unit {
    return new Unit({
      id: '',
    });
  }

  static fromThingUnit(unit: ThingUnit, key: string): Unit {
    let installed = unit.installed;
    if (unit.l) {
      installed = true;
    }
    return new Unit({
      id: key,
      extPort: unit.ext_port
        ? unit.ext_port
        : 10100 + toNumber(key.replace('unit', '')),
      type: unit.type,
      connectionStatus: unit.connection_status,
      alarms: unit.alarms,
      installed,
      ipAddress: unit.ip_address ? unit.ip_address : unit.ip,
      name: unit.label ? unit.label : unit.l,
      operationStatus:
        unit.os ?? unit.operation_status ?? unit.high_speed_operation,
      password: unit.password,
      productType: unit.product_type
        ? unit.product_type
        : getProductType(unit.pt),
      proxy: unit.proxy,
      proxyTTL: unit.proxy_ttl,
      proxyURL: unit.proxy_url,
      timestamp: unit.timestamp,
      user: unit.user,
      version: unit.version,

      ahuEnergyConsumption: new ValueWithUnit({
        value:
          sumConverter(unit.aecmwh, unit.aeckwh, value => value / 1000) ??
          unit.ahu_energy_consumption,
        unit:
          unit.aecmwh_u ??
          unit.ahu_energy_consumption_unit ??
          DefaultValueUnit.MWh,
      }),
      coolPowerRecovery: new ValueWithUnit({
        value:
          sumConverter(unit.cprkw, unit.cprw, value => value / 1000) ??
          unit.cool_power_recovery,
        unit:
          unit.cprkw_u ?? unit.cool_power_recovery_unit ?? DefaultValueUnit.KW,
      }),
      extractAir: new ValueWithUnit({
        value: unit.ea ?? unit.extract_air,
        unit: unit.ea_u ?? unit.extract_air_unit ?? DefaultValueUnit.Ls,
      }),
      extractAirDuct: new ValueWithUnit({
        value: unit.ead ?? unit.extract_air_duct,
        unit: unit.ead_u ?? unit.extract_air_duct_unit ?? DefaultValueUnit.Pa,
      }),
      extractAirTemperature: new ValueWithUnit({
        value: unit.eat ?? unit.extract_air_temp,
        unit: unit.eat_u ?? unit.extract_air_temp_unit ?? DefaultValueUnit.C,
      }),
      heatCoolEnergyConsumption: new ValueWithUnit({
        value:
          sumConverter(unit.hcecmwh, unit.hceckwh, value => value / 1000) ??
          unit.heat_cool_energy_consumption,
        unit:
          unit.hcecmwh_u ??
          unit.heat_cool_energy_consumption_unit ??
          DefaultValueUnit.MWh,
      }),
      heatPowerRecovery: new ValueWithUnit({
        value:
          sumConverter(unit.hprkw, unit.hprw, value => value / 1000) ??
          unit.heat_power_recovery,
        unit:
          unit.hprkw_u ?? unit.heat_power_recovery_unit ?? DefaultValueUnit.KW,
      }),
      hx: new ValueWithUnit({
        value: unit.hx,
        unit: unit.hx_u ?? unit.hx_unit,
      }),
      outdoorAirTemperature: new ValueWithUnit({
        value: unit.oat ?? unit.outdoor_air_temp,
        unit: unit.oat_u ?? unit.outdoor_air_temp_unit ?? DefaultValueUnit.C,
      }),
      sfp: new ValueWithUnit({
        value: unit.sfp,
        unit: unit.sfp_u ?? unit.sfp_unit ?? DefaultValueUnit.KWM3s,
      }),
      supplyAir: new ValueWithUnit({
        value: unit.sa ?? unit.supply_air,
        unit: unit.sa_u ?? unit.supply_air_unit ?? DefaultValueUnit.Ls,
      }),
      supplyAirDuct: new ValueWithUnit({
        value: unit.sad ?? unit.supply_air_duct,
        unit: unit.sad_u ?? unit.supply_air_duct_unit ?? DefaultValueUnit.Pa,
      }),
      supplyAirTemperature: new ValueWithUnit({
        value: unit.sat ?? unit.supply_air_temp,
        unit: unit.sat_u ?? unit.supply_air_temp_unit ?? DefaultValueUnit.C,
      }),
    });
  }
}

/**
 * Visible for testing.
 */
export const mockUnit = new Unit({
  id: 'unit1',
  extPort: 101010,
  type: 'product',
  connectionStatus: ConnectionStatus.Online,
  alarms: [
    {
      id: '33:1',
      prio: AlarmPrio.A,
      time: 1591795313,
    },
    {
      id: '98:7',
      prio: AlarmPrio.B,
      time: 1592398159,
    },
  ],

  installed: true,
  ipAddress: '192.168.1.2',
  name: 'Test Unit',
  operationStatus: 5,
  password: '',
  productType: ProductType.GoldF,
  proxy: 'on',
  proxyTTL: 1800,
  proxyURL: 'https://testunitproxyurl.xom:443',
  user: 'Test',
  version: '1.10',

  ahuEnergyConsumption: new ValueWithUnit({
    value: 1,
    unit: 'MWh',
  }),
  coolPowerRecovery: new ValueWithUnit({
    value: 2,
    unit: 'kW',
  }),
  extractAir: new ValueWithUnit({
    value: 3,
    unit: 'm3/s',
  }),
  extractAirDuct: new ValueWithUnit({
    value: 4,
    unit: 'Pa',
  }),
  extractAirTemperature: new ValueWithUnit({
    value: 5,
    unit: 'C',
  }),
  heatCoolEnergyConsumption: new ValueWithUnit({
    value: 6,
    unit: 'MWh',
  }),
  heatPowerRecovery: new ValueWithUnit({
    value: 7,
    unit: 'kW',
  }),
  hx: new ValueWithUnit({
    value: 8,
    unit: '%',
  }),
  outdoorAirTemperature: new ValueWithUnit({
    value: 9,
    unit: 'C',
  }),
  sfp: new ValueWithUnit({
    value: 10,
    unit: 'kW/m3/s',
  }),
  supplyAir: new ValueWithUnit({
    value: 11,
    unit: 'm3/s',
  }),
  supplyAirDuct: new ValueWithUnit({
    value: 12,
    unit: 'Pa',
  }),
  supplyAirTemperature: new ValueWithUnit({
    value: 13,
    unit: 'C',
  }),
});

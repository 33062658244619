import { AxiosInstance } from '../../api/axios';
import { BACKEND_REST_API_BASE_URL } from '../../config';
import {
  stringToArrayBuffer,
  arrayBufferToString,
} from '../../utils/ArrayBufferUtils';
import { Optional } from '../../utils/Optional';
import { isString } from '../../utils/Utils';
import { AuthService } from '../auth/AuthService';
import { EncryptionServiceInterface } from './EncryptionServiceInterface';

class EncryptionServiceImplementation implements EncryptionServiceInterface {
  async getPublicKey(thingId: string): Promise<string> {
    const { data } = await AxiosInstance.get(
      `${BACKEND_REST_API_BASE_URL}/things/${thingId}/cert`,
      {
        baseURL: '',
        headers: {
          authorization: AuthService.getToken(),
          'x-auth-token': AuthService.getToken(),
        },
      }
    );
    let publicKey = data;

    if (isString(publicKey)) {
      const pemHeader = '-----BEGIN PUBLIC KEY-----';
      const pemFooter = '-----END PUBLIC KEY-----';
      publicKey = publicKey.replace(pemHeader, '');
      publicKey = publicKey.replace(pemFooter, '');
      publicKey = publicKey.replace(/\n/g, '');
      publicKey = publicKey.trim();
      return publicKey;
    }

    return '';
  }

  async getEncryptionKey(thingId: string): Promise<CryptoKey> {
    const publicKey = await this.getPublicKey(thingId);
    const decodedPublicKey = atob(publicKey);
    const binaryPublicKey = stringToArrayBuffer(decodedPublicKey);

    const encryptionKey = await crypto.subtle.importKey(
      'spki',
      binaryPublicKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      false,
      ['encrypt']
    );

    return encryptionKey;
  }

  async encrypt(
    thingId: string,
    value: string,
    label?: Optional<string>
  ): Promise<string> {
    const encryptionKey = await this.getEncryptionKey(thingId);
    const encryptedValue = await crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
        label: label ? stringToArrayBuffer(label) : undefined,
      },
      encryptionKey,
      stringToArrayBuffer(value)
    );
    const encryptedString = arrayBufferToString(encryptedValue);
    const base64EncodedString = btoa(encryptedString);

    return base64EncodedString;
  }
}

export const EncryptionService = new EncryptionServiceImplementation();

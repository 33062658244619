/* eslint-disable @typescript-eslint/camelcase */
import { AxiosResponse } from 'axios';
import { AxiosInstance } from '../../api/axios';
import {
  BACKEND_REST_API_BASE_URL,
  CLOUD_REST_API_BASE_URL,
} from '../../config';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { isDefined, isEmpty, isString, pickDefined } from '../../utils/Utils';
import { AuthService } from '../auth/AuthService';
import { CacheService } from '../cache/CacheService';
import { DomainsService } from '../domains/DomainsService';
import { UnitsService } from '../units/UnitsService';
import { DistributorLocation } from './models/DistributorLocation';
import { GetThing } from './models/GetThing';
import { GetThingSubscription } from './models/GetThingSubscription';
import { SubscriptionStatus } from './models/SubscriptionStatus';
import { ThingStateFull } from './models/ThingStateFull';
import { UpdateThingParameters } from './models/UpdateThingParameters';
import { ThingsMQTTService } from './ThingsMQTTService';
import { ThingsServiceInterface } from './ThingsServiceInterface';

const FROM_INDEX = 0;
const MAX_QUERY_SIZE = 10000;

class ThingsServiceImplementation implements ThingsServiceInterface {
  private cacheNamespace = 'things';

  private _normalizeSMSNumbers(data: GetThing) {
    if (data.shadow?.state?.reported?.sms_number_1 === 'EMPTY') {
      data.shadow.state.reported.sms_number_1 = '';
    }

    if (data.shadow?.state?.reported?.sms_number_2 === 'EMPTY') {
      data.shadow.state.reported.sms_number_2 = '';
    }
  }

  private async _getThing(thingId: string): Promise<GetThing> {
    const ENDPOINT = '/things';
    const { data } = await AxiosInstance.get<GetThing>(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        headers: { authorization: AuthService.getToken() },
        params: { thingName: thingId },
      }
    );
    return data;
  }

  private async _getThingSubscription(
    thingId: string
  ): Promise<GetThingSubscription> {
    const ENDPOINT = '/things';
    const { data } = await AxiosInstance.get<GetThingSubscription>(
      `${BACKEND_REST_API_BASE_URL}${ENDPOINT}/${thingId}`,
      {
        baseURL: '',
        headers: {
          authorization: AuthService.getToken(),
          'x-auth-token': AuthService.getToken(),
        },
      }
    );
    return data;
  }

  private async _getNetworkedThings(thing: Thing): Promise<Thing> {
    const _thing = new Thing(thing);

    if (_thing.isNetworkedThingsSupported && _thing.units) {
      const unitsRequests = _thing.installedUnits.map(unit => {
        const unitThingId = `${thing.thingName}__${unit.id}`;
        return this._getThing(unitThingId).catch(() => undefined);
      });
      const unitsRequestsResult = await Promise.all(unitsRequests);

      const updatedUnits = unitsRequestsResult
        .filter(isDefined)
        .map(unit => {
          const reportedState = pickDefined(unit?.shadow?.state?.reported);
          const [, unitId] = unit.thingName.split('__');
          if (!isDefined(unitId)) return;

          return Unit.fromThingUnit(reportedState, unitId);
        })
        .filter(isDefined);
      const units = _thing.units.map(unit => {
        const updatedUnit = updatedUnits.find(u => u.id === unit.id);

        if (isDefined(updatedUnit)) {
          const definedUpdatedUnit = pickDefined(updatedUnit);
          return new Unit({ ...unit, ...definedUpdatedUnit });
        }

        return unit;
      });

      _thing.units = [...units];
    }

    return _thing;
  }

  private async _updateThing(thing: Thing): Promise<void> {
    const thingId = thing.thingName;
    const ENDPOINT = `/things/${thingId}`;

    await AxiosInstance.patch(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        domain: thing.domain.id,
        label: thing.name,
        description: thing.description,
      },
      {
        headers: {
          authorization: AuthService.getToken(),
        },
      }
    );
  }

  async getThings(): Promise<Thing[]> {
    const ENDPOINT = '/things/find';
    const cacheKey = 'things';
    const cachedThings = CacheService.get<Thing[]>(
      this.cacheNamespace,
      cacheKey
    );

    if (isDefined(cachedThings)) {
      return cachedThings;
    } else {
      const { data } = await AxiosInstance.post(
        `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
        {
          query: {
            from: FROM_INDEX,
            size: MAX_QUERY_SIZE,
            sort: [{ thingName: { order: 'asc' } }],
            query: { match_all: {} },
          },
        },
        {
          headers: {
            authorization: AuthService.getToken(),
          },
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const hits: Record<string, any>[] = Array.from(data?.hits?.hits ?? []);
      const things = hits
        .map(hit =>
          Thing.fromListThing({
            ...hit,
            ...hit?._source,
          })
        )
        // ? Ignore networked things, i.e. <thingName>__<value>.
        .filter(hit => !hit.thingName.includes('__'));

      CacheService.set<Thing[]>(this.cacheNamespace, cacheKey, things);

      return things;
    }
  }

  async getThing(thingId: string, forceRefresh = false): Promise<Thing> {
    const cachedThing = CacheService.get<Thing>(this.cacheNamespace, thingId);

    if (isDefined(cachedThing) && forceRefresh === false) {
      return cachedThing;
    } else {
      const getThing = await this._getThing(thingId).catch(
        () => ({} as GetThing)
      );
      const getThinSubscription = await this._getThingSubscription(
        thingId
      ).catch(() => ({} as GetThingSubscription));
      const { subscriptionStatus, country } = getThinSubscription;
      let distributorLocation = undefined;

      if (
        isDefined(country) &&
        subscriptionStatus === SubscriptionStatus.Pause
      ) {
        distributorLocation = await this.getDistributorLocation(country);
      }
      this._normalizeSMSNumbers(getThing);

      let thing = Thing.fromGetThing(
        getThing,
        getThinSubscription,
        distributorLocation
      );
      thing = await this._getNetworkedThings(thing);

      CacheService.set<Thing>(this.cacheNamespace, thingId, thing);

      return thing;
    }
  }

  async getDistributorLocation(location: string): Promise<DistributorLocation> {
    const defaultDistributor = {
      costCenter: 'N/A',
      countryName: 'N/A',
      email: 'support@swegonconnect.com',
      id: '-1',
    };

    try {
      const {
        data,
      }: AxiosResponse<DistributorLocation> = await AxiosInstance.get(
        `${BACKEND_REST_API_BASE_URL}/distributors/location/${location}`,
        {
          baseURL: '',
          headers: {
            authorization: AuthService.getToken(),
            'x-auth-token': AuthService.getToken(),
          },
        }
      );

      return { ...defaultDistributor, ...data };
    } catch (error) {
      return defaultDistributor;
    }
  }

  async updateThing({
    newThing,
    oldThing,
  }: UpdateThingParameters): Promise<void> {
    const desiredState: ThingStateFull = {};
    if (newThing?.smsPhoneNumber1 !== oldThing?.smsPhoneNumber1) {
      desiredState.sms_number_1 = newThing.smsPhoneNumber1;
    }
    if (newThing?.smsPhoneNumber2 !== oldThing?.smsPhoneNumber2) {
      desiredState.sms_number_2 = newThing.smsPhoneNumber2;
    }

    if (newThing.lanIpAddress !== oldThing.lanIpAddress) {
      desiredState.lan_ip = newThing.lanIpAddress;
    }

    if (newThing.lanNetmask !== oldThing.lanNetmask) {
      desiredState.lan_netmask = newThing.lanNetmask;
    }

    const reportedState: ThingStateFull = {};
    if (newThing.latitudeLongitude !== oldThing.latitudeLongitude) {
      reportedState.latlng = newThing.latitudeLongitude;
    }

    const unitsPromises =
      newThing.units?.map(async newUnit => {
        const oldUnit = oldThing.units?.find(u => u.id === newUnit.id);
        if (!isDefined(oldUnit) || this.equals(newUnit, oldUnit)) return;

        return UnitsService.updateUnit({
          thing: newThing,
          newUnit,
          oldUnit,
        });
      }) ?? [];

    await Promise.all([
      ...unitsPromises,
      this._updateThing(newThing),
      ThingsMQTTService.publishUpdate({
        domainPath: newThing.domain.topic,
        thingId: newThing.thingName,
        state: reportedState,
        useReportedState: true,
      }),
      ThingsMQTTService.publishUpdate({
        domainPath: newThing.domain.topic,
        thingId: newThing.thingName,
        state: desiredState,
        useReportedState: false,
      }),
    ]);
  }

  private equals(newUnit: Unit, oldUnit: Unit): boolean {
    return (
      newUnit.id === oldUnit.id &&
      newUnit.type === oldUnit.type &&
      newUnit.ipAddress === oldUnit.ipAddress &&
      newUnit.password === oldUnit.password &&
      newUnit.username === oldUnit.username
    );
  }

  searchThings(allThings: Thing[], searchTerm: string): Thing[] {
    let things = [...allThings];

    if (isDefined(searchTerm) && !isEmpty(searchTerm)) {
      const lowerSearchTerm = searchTerm.toLowerCase();

      things = things.filter(thing => {
        const values = Object.values(thing)
          .filter(isDefined)
          .map(value => {
            return isString(value)
              ? value.toLowerCase()
              : String(value).toLowerCase();
          });
        let domainPath = '';
        if (thing.domain.id === 'root') {
          domainPath = 'root';
        } else {
          domainPath = DomainsService.getDomainPath(
            thing.domain.id
          ).toLowerCase();
        }
        values.push(domainPath);

        return values.some(value => value.includes(lowerSearchTerm));
      });
    }

    return things;
  }

  async removeThing(thingId: string): Promise<void> {
    const ENDPOINT = `/things/${thingId}`;

    await AxiosInstance.delete(`${CLOUD_REST_API_BASE_URL}${ENDPOINT}`, {
      headers: { authorization: AuthService.getToken() },
      params: { thingName: thingId },
    });
  }
}

export const ThingsService = new ThingsServiceImplementation();

import { SubscriptionState } from 'aws-sdk/clients/shield';
import semver from 'semver';
import { DomainsService } from '../services/domains/DomainsService';
import { ConnectionStatus } from '../services/things/models/ConnectionStatus';
import { DistributorLocation } from '../services/things/models/DistributorLocation';
import { GetThing } from '../services/things/models/GetThing';
import { GetThingSubscription } from '../services/things/models/GetThingSubscription';
import { ListThing } from '../services/things/models/ListThing';
import { SubscriptionStatus } from '../services/things/models/SubscriptionStatus';
import { ThingStateFull } from '../services/things/models/ThingStateFull';
import { ThingUnit } from '../services/things/models/ThingUnit';
import { Optional } from '../utils/Optional';
import { isDefined, isEmpty } from '../utils/Utils';
import { Coordinates } from './Coordinates';
import { Domain, mockDomain } from './Domain';
import { ThingFields } from './ThingFields';
import { ThingInterface } from './ThingInterface';
import { ThingPendingChange } from './ThingPendingChange';
import { ThingPendingChangeType } from './ThingPendingChangeType';
import { mockUnit, Unit } from './Unit';

const SIGNAL_STRENGTH_PERCENTAGE: Record<string, number> = {
  '-113': 0,
  '-112': 1,
  '-111': 2,
  '-110': 4,
  '-109': 5,
  '-108': 6,
  '-107': 8,
  '-106': 10,
  '-105': 11,
  '-104': 12,
  '-103': 14,
  '-102': 16,
  '-101': 18,
  '-100': 20,
  '-99': 22,
  '-98': 23,
  '-97': 24,
  '-96': 26,
  '-95': 28,
  '-94': 30,
  '-93': 31,
  '-92': 32,
  '-91': 34,
  '-90': 36,
  '-89': 38,
  '-88': 40,
  '-87': 41,
  '-86': 42,
  '-85': 44,
  '-84': 46,
  '-83': 48,
  '-82': 49,
  '-81': 50,
  '-80': 52,
  '-79': 54,
  '-78': 56,
  '-77': 58,
  '-76': 60,
  '-75': 61,
  '-74': 62,
  '-73': 64,
  '-72': 66,
  '-71': 68,
  '-70': 69,
  '-69': 70,
  '-68': 71,
  '-67': 72,
  '-66': 73,
  '-65': 74,
  '-64': 76,
  '-63': 78,
  '-62': 80,
  '-61': 82,
  '-60': 83,
  '-59': 84,
  '-58': 86,
  '-57': 88,
  '-56': 90,
  '-55': 92,
  '-54': 93,
  '-53': 94,
  '-52': 96,
  '-51': 98,
  '-50': 100,
};

export class Thing implements ThingFields, ThingInterface {
  domain: Domain;
  thingName: string;
  isInactive: boolean;

  automaticLatitudeLongitude?: Optional<string>;
  connectionStatus?: Optional<number>;
  createdAt?: Optional<string>;
  createdBy?: Optional<string>;
  description?: Optional<string>;
  desiredThing?: Optional<Thing>;
  firmwareVersion?: Optional<string>;
  gatewayId?: Optional<string>;
  iccid?: Optional<string>;
  ipAddress?: Optional<string>;
  lanIpAddress?: Optional<string>;
  lanNetmask?: Optional<string>;
  lastUpdate?: Optional<number>;
  latitudeLongitude?: Optional<string>;
  name?: Optional<string>;
  networkType?: Optional<string>;
  progress?: Optional<string>;
  proxy?: Optional<string>;
  proxyTTL?: Optional<number>;
  proxyURL?: Optional<string>;
  rssi?: Optional<number>;
  signalStrength?: Optional<string>;
  smsPhoneNumber1?: Optional<string>;
  smsPhoneNumber2?: Optional<string>;
  subscriptionEmail?: Optional<string>;
  subscriptionEndDate?: Optional<string>;
  subscriptionStatus?: Optional<SubscriptionState>;
  thingType?: Optional<string>;
  units?: Optional<Unit[]>;
  nineToTwentyFour?: Optional<boolean>;

  constructor({
    domain,
    thingName,
    isInactive = false,

    automaticLatitudeLongitude,
    connectionStatus,
    createdAt,
    createdBy,
    description,
    desiredThing,
    firmwareVersion,
    gatewayId,
    iccid,
    ipAddress,
    lanIpAddress,
    lanNetmask,
    lastUpdate,
    latitudeLongitude,
    name,
    networkType,
    progress,
    proxy,
    proxyTTL = 1800,
    proxyURL,
    rssi,
    signalStrength,
    smsPhoneNumber1,
    smsPhoneNumber2,
    subscriptionEmail,
    subscriptionEndDate,
    subscriptionStatus,
    thingType,
    units,
    nineToTwentyFour,
  }: ThingFields) {
    this.domain = new Domain(domain ?? {});
    this.thingName = thingName;
    this.isInactive = isInactive;

    this.automaticLatitudeLongitude = automaticLatitudeLongitude;
    this.connectionStatus = connectionStatus;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.description = description;
    this.desiredThing = isDefined(desiredThing)
      ? new Thing(desiredThing)
      : undefined;
    this.firmwareVersion = firmwareVersion;
    this.gatewayId = gatewayId;
    this.iccid = iccid;
    this.ipAddress = ipAddress;
    this.lanIpAddress = lanIpAddress;
    this.lanNetmask = lanNetmask;
    this.lastUpdate = lastUpdate;
    this.latitudeLongitude = latitudeLongitude;
    this.name = name;
    this.networkType = networkType;
    this.progress = progress;
    this.proxy = proxy;
    this.proxyTTL = proxyTTL;
    this.proxyURL = proxyURL;
    this.rssi = rssi;
    this.signalStrength = signalStrength;
    this.smsPhoneNumber1 = smsPhoneNumber1;
    this.smsPhoneNumber2 = smsPhoneNumber2;
    this.subscriptionEmail = subscriptionEmail;
    this.subscriptionEndDate = subscriptionEndDate;
    this.subscriptionStatus = subscriptionStatus;
    this.thingType = thingType;
    this.units = units ? units.map(unit => new Unit(unit)) : undefined;
    this.nineToTwentyFour = nineToTwentyFour;
  }

  get alarmSupportedUnits(): Unit[] {
    return this.installedUnits.filter(unit => unit.isAlarmsSupported);
  }

  get domainPath(): string {
    return DomainsService.getDomainPath(this.domain.id);
  }

  get googleMapsURL(): string {
    const manualOrAutomatic =
      this.latitudeLongitude ?? this.automaticLatitudeLongitude;

    if (isDefined(manualOrAutomatic)) {
      return `https://www.google.com/maps/search/?api=1&query=${manualOrAutomatic}`;
    }

    return '';
  }

  get hasAlarms(): boolean {
    return (
      this.hasInstalledUnits && this.installedUnits.some(unit => unit.hasAlarms)
    );
  }

  get hasAlarmSupportedUnits(): boolean {
    return this.alarmSupportedUnits.length > 0;
  }

  get hasInstalledUnits(): boolean {
    return this.installedUnits.length > 0;
  }

  get hasParameters(): boolean {
    return (
      this.hasParametersSupportedUnits &&
      this.parametersSupportedUnits.some(unit => unit.hasParameters)
    );
  }

  get hasParametersSupportedUnits(): boolean {
    return this.parametersSupportedUnits.length > 0;
  }

  get hasPendingChanges(): boolean {
    return this.pendingChanges.length > 0;
  }

  get hasUnits(): boolean {
    if (isDefined(this.units)) {
      return this.units.length > 0;
    }
    return false;
  }

  get installedUnits(): Unit[] {
    const units = this.units ?? [];
    return units.filter(unit => unit.isInstalled);
  }

  get isActive(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.Active;
  }

  get isAlarmsSupported(): boolean {
    return this.satisfiesFirmwareVersion('>=2.3.5');
  }

  get isConnectable(): boolean {
    return this.isOnline && !isEmpty(this.ipAddress);
  }

  get isIotOnly(): boolean {
    return this.connectionStatus === ConnectionStatus.IoTOnly;
  }

  get isEditSupported(): boolean {
    return this.satisfiesFirmwareVersion('>=2.0.0');
  }

  get isOffline(): boolean {
    return this.connectionStatus === ConnectionStatus.Offline;
  }

  get isOnline(): boolean {
    return (
      this.connectionStatus === ConnectionStatus.Online ||
      this.connectionStatus === ConnectionStatus.IoTOnly
    );
  }

  get isParametersSupported(): boolean {
    return this.satisfiesFirmwareVersion('>=3.0.0');
  }

  get isPaused(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.Pause;
  }

  get isTerminated(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.Terminated;
  }

  get isUpdateSupported(): boolean {
    return this.satisfiesFirmwareVersion('>=3.0.0');
  }

  get isUsingAutomaticCoordinates(): boolean {
    return (
      isDefined(this.automaticLatitudeLongitude) &&
      !isDefined(this.latitudeLongitude)
    );
  }

  get coordinates(): Coordinates | undefined {
    const manualOrAutomatic =
      this.latitudeLongitude ?? this.automaticLatitudeLongitude;

    const latitudeLongitude = manualOrAutomatic
      ?.split(',')
      .map(value => value.trim())
      .map(value => parseFloat(value))
      .filter(value => !isNaN(value));

    if (latitudeLongitude?.length === 2) {
      return {
        latitude: latitudeLongitude[0],
        longitude: latitudeLongitude[1],
      };
    }

    return undefined;
  }

  get isNetworkedThingsSupported(): boolean {
    return this.satisfiesFirmwareVersion('>=4.0.0');
  }

  get numberOfAlarms(): number {
    const alarms = this.installedUnits
      .map(unit => unit.alarms)
      .filter(isDefined)
      .reduce((previous, current) => [...previous, ...current], []);

    return alarms.length;
  }

  get numberOfInstalledUnits(): number {
    return this.installedUnits.length;
  }

  get parametersSupportedUnits(): Unit[] {
    return this.installedUnits.filter(unit => unit.isParametersSupported);
  }

  get pendingChanges(): ThingPendingChange[] {
    const pendingChanges = new Array<ThingPendingChange>();

    const isValuesDefined = (a: unknown, b: unknown) =>
      isDefined(a) && isDefined(b);
    const isValuesDifferent = (a: unknown, b: unknown) => a !== b;

    if (
      isValuesDefined(this.desiredThing?.lanIpAddress, this.lanIpAddress) &&
      isValuesDifferent(this.desiredThing?.lanIpAddress, this.lanIpAddress)
    ) {
      pendingChanges.push(
        new ThingPendingChange({
          type: ThingPendingChangeType.IP_ADDRESS,
          oldValue: this.lanIpAddress,
          newValue: this.desiredThing?.lanIpAddress,
        })
      );
    }

    if (
      isValuesDefined(this.desiredThing?.lanNetmask, this.lanNetmask) &&
      isValuesDifferent(this.desiredThing?.lanNetmask, this.lanNetmask)
    ) {
      pendingChanges.push(
        new ThingPendingChange({
          type: ThingPendingChangeType.NETMASK,
          oldValue: this.lanNetmask,
          newValue: this.desiredThing?.lanNetmask,
        })
      );
    }

    this.units?.forEach(unit => {
      const desiredUnit = this.desiredThing?.units?.find(
        desiredUnit => desiredUnit.id === unit.id
      );

      if (
        isValuesDefined(desiredUnit?.ipAddress, unit?.ipAddress) &&
        isValuesDifferent(desiredUnit?.ipAddress, unit.ipAddress)
      ) {
        pendingChanges.push(
          new ThingPendingChange({
            type: ThingPendingChangeType.UNIT,
            oldValue: unit?.ipAddress,
            newValue: desiredUnit?.ipAddress,
          })
        );
      }
    });

    if (
      isValuesDefined(
        this.desiredThing?.smsPhoneNumber1,
        this.smsPhoneNumber1
      ) &&
      isValuesDifferent(
        this.desiredThing?.smsPhoneNumber1,
        this.smsPhoneNumber1
      )
    ) {
      pendingChanges.push(
        new ThingPendingChange({
          type: ThingPendingChangeType.SMS_NUMBER,
          oldValue: this.smsPhoneNumber1,
          newValue: this.desiredThing?.smsPhoneNumber1,
        })
      );
    }

    if (
      isValuesDefined(
        this.desiredThing?.smsPhoneNumber2,
        this.smsPhoneNumber2
      ) &&
      this.desiredThing?.smsPhoneNumber2 !== this.smsPhoneNumber2
    ) {
      pendingChanges.push(
        new ThingPendingChange({
          type: ThingPendingChangeType.SMS_NUMBER,
          oldValue: this.smsPhoneNumber2,
          newValue: this.desiredThing?.smsPhoneNumber2,
        })
      );
    }

    return pendingChanges;
  }

  satisfiesFirmwareVersion(semverFirmwareVersion: string): boolean {
    if (this.firmwareVersion) {
      return semver.satisfies(this.firmwareVersion, semverFirmwareVersion);
    }
    return false;
  }

  get signalStrengthInPercentage(): number {
    if (isDefined(this.signalStrength)) {
      const [value = ''] = this.signalStrength.split(' ');
      const trimmedValue = value.trim();

      return SIGNAL_STRENGTH_PERCENTAGE[trimmedValue] ?? 0;
    }

    if (isDefined(this.rssi)) {
      return this.rssi;
    }

    return 0;
  }

  get simplifiedNetworkType(): string {
    switch (this.networkType) {
      case 'GPRS':
      case 'EDGE':
      case 'CDMA':
      case '1xRTT':
      case 'IDEN':
        return '2G';
      case 'UMTS':
      case 'EVDO_0':
      case 'EVDO_A':
      case 'HSDPA':
      case 'HSUPA':
      case 'HSPA':
      case 'EVDO_B':
      case 'EHRPD':
      case 'HSPAP':
        return '3G';
      case 'LTE':
        return '4G';
      case 'N/A':
        return '';
      default:
        return this.networkType ?? '';
    }
  }

  get uninstalledUnits(): Unit[] {
    const units = this.units ?? [];
    return units.filter(unit => !unit.isInstalled);
  }

  get updateStatus(): string {
    switch (this.progress) {
      case 'Error':
        return 'ERROR';

      case 'Finished':
        return 'FINISHED';

      case 'Running':
        return '';

      case 'Rebooting':
        return 'REBOOTING';

      case 'Updating':
        return 'UPDATING';

      case 'Downloading':
        return 'DOWNLOADING';

      case 'Started':
        return 'STARTED';

      default:
        return '';
    }
  }

  static empty(): Thing {
    return new Thing({
      domain: {
        id: '',
        topic: '',
      },
      isInactive: false,
      thingName: '',
    });
  }

  static fromThingState(thing: Thing, state: ThingStateFull): Thing {
    const thingStateUnitRecord = (state as unknown) as Record<
      string,
      ThingUnit
    >;
    const tcxn = state?.tcxn;

    const firmwareVersion = state?.fw_version ?? state?.firmware_version;

    const units = Object.keys(state ?? {})
      .filter(key => key.includes('unit'))
      .map(key => Unit.fromThingUnit(thingStateUnitRecord[key], key));

    return new Thing({
      ...thing,
      units: [...units],
      isInactive: !isDefined(state),
      automaticLatitudeLongitude: state?.aut_latlng,
      connectionStatus: tcxn?.connection_status,
      firmwareVersion,
      gatewayId: state?.gateway_id,
      iccid: tcxn?.iccid ?? tcxn?.cellular?.iccid,
      ipAddress: state?.ip_addr,
      lanIpAddress: state?.lan_ip,
      lanNetmask: state?.lan_netmask,
      latitudeLongitude: state?.latlng,
      networkType: tcxn?.network_type ?? tcxn?.cellular?.network_type,
      progress: state?.progress,
      proxy: state?.proxy,
      proxyTTL: state?.proxy_ttl,
      proxyURL: state?.proxy_url,
      rssi: tcxn?.rssi ?? tcxn?.cellular?.rssi,
      signalStrength: state?.signal_strength,
      smsPhoneNumber1: state?.sms_number_1,
      smsPhoneNumber2: state?.sms_number_2,
    });
  }

  static fromGetThing(
    thing: GetThing,
    thingSubscription?: GetThingSubscription,
    distributorLocation?: DistributorLocation
  ): Thing {
    const reportedState = thing?.shadow?.state?.reported;
    const desiredState = thing?.shadow?.state?.desired;
    const metadata = thing?.shadow?.metadata;
    const tcxn = reportedState?.tcxn;

    const domain = thing?.domain ?? {};
    const firmwareVersion =
      reportedState?.fw_version ?? reportedState?.firmware_version;

    const thingStateUnitRecord = (reportedState as unknown) as Record<
      string,
      ThingUnit
    >;
    const units = Object.keys(reportedState ?? {})
      .filter(key => key.includes('unit'))
      .map(key => Unit.fromThingUnit(thingStateUnitRecord[key], key));

    let desiredThing: Thing | undefined;

    if (isDefined(desiredState)) {
      const desiredGetThing: GetThing = {
        ...thing,
        shadow: {
          metadata: {},
          state: { reported: desiredState },
        },
      };
      desiredThing = this.fromGetThing(
        desiredGetThing,
        thingSubscription,
        distributorLocation
      );
    }
    return new Thing({
      createdAt: thing.createdAt,
      createdBy: thing.createdBy,
      domain: new Domain({ ...domain, topic: thing.domainTopic }),
      name: thing.label,
      thingName: thing.thingName,
      thingType: thing.thingType,
      units: [...units],
      isInactive: !isDefined(reportedState),
      desiredThing,

      automaticLatitudeLongitude: reportedState?.aut_latlng,
      connectionStatus: tcxn?.connection_status,
      description: thing.description,
      firmwareVersion,
      gatewayId: reportedState?.gateway_id,
      iccid: tcxn?.iccid ?? tcxn?.cellular?.iccid,
      ipAddress: reportedState?.ip_addr,
      lanIpAddress: reportedState?.lan_ip,
      lanNetmask: reportedState?.lan_netmask,
      lastUpdate: metadata?.reported?.tcxn?.connection_status?.timestamp,
      latitudeLongitude: reportedState?.latlng,
      networkType: tcxn?.network_type ?? tcxn?.cellular?.network_type,
      progress: reportedState?.progress,
      proxy: reportedState?.proxy,
      proxyTTL: reportedState?.proxy_ttl,
      proxyURL: reportedState?.proxy_url,
      rssi: tcxn?.rssi ?? tcxn?.cellular?.rssi,
      signalStrength: reportedState?.signal_strength,
      smsPhoneNumber1: reportedState?.sms_number_1,
      smsPhoneNumber2: reportedState?.sms_number_2,
      subscriptionEmail: distributorLocation?.email,
      subscriptionEndDate: thingSubscription?.subscriptionEndDate,
      subscriptionStatus: thingSubscription?.subscriptionStatus,
      nineToTwentyFour: thingSubscription?.nineToTwentyFour,
    });
  }

  static fromListThing(thing: ListThing): Thing {
    const state = thing?.state;
    const tcxn = state?.tcxn;
    const metadata = thing?.metadata;

    const firmwareVersion = state?.fw_version ?? '';

    return new Thing({
      createdAt: thing.createdAt,
      createdBy: thing.createdBy,
      domain: {
        id: thing.domain,
        name: '',
        description: '',
        topic: '',
      },
      name: thing.label,
      thingName: thing.thingName,
      thingType: thing.thingType,
      units: [],
      isInactive: !isDefined(state),

      automaticLatitudeLongitude: state?.aut_latlng,
      connectionStatus: tcxn?.connection_status,
      description: thing.description,
      firmwareVersion,
      gatewayId: state?.gateway_id,
      iccid: tcxn?.iccid ?? tcxn?.cellular?.iccid,
      ipAddress: state?.ip_addr,
      lanIpAddress: state?.lan_ip,
      lanNetmask: state?.lan_netmask,
      lastUpdate: metadata?.tcxn?.connection_status?.timestamp,
      latitudeLongitude: state?.latlng,
      networkType: tcxn?.network_type ?? tcxn?.cellular?.network_type,
      progress: state?.progress,
      proxy: state?.proxy,
      proxyTTL: state?.proxy_ttl,
      proxyURL: state?.proxy_url,
      rssi: tcxn?.rssi ?? tcxn?.cellular?.rssi,
      signalStrength: state?.signal_strength,
      smsPhoneNumber1: state?.sms_number_1,
      smsPhoneNumber2: state?.sms_number_2,
    });
  }
}

/**
 * Visible for testing.
 */
export const mockThing = new Thing({
  createdAt: '2016-06-28T09:09:56.703Z',
  createdBy: 'Test',
  name: 'Test Thing',
  thingName: '00001234',
  thingType: '2',
  domain: mockDomain,
  units: [mockUnit],
  isInactive: false,

  automaticLatitudeLongitude: '56.16156, 15.58661',
  connectionStatus: ConnectionStatus.Online,
  description: 'A thing that is used in tests.',
  firmwareVersion: '4.0.0',
  gatewayId: '1234567',
  iccid: '123456789',
  ipAddress: '10.100.1.10',
  lanIpAddress: '192.168.1.1',
  lanNetmask: '255.255.0.0',
  latitudeLongitude: '56.16156, 15.58661',
  networkType: 'LTE',
  progress: 'Running',
  proxy: 'on',
  proxyTTL: 1800,
  proxyURL: 'https://testthingproxyurl.xom:443',
  signalStrength: '-100 dBm',
  smsPhoneNumber1: '+46708101010',
  smsPhoneNumber2: '+46708202020',
  subscriptionEmail: 'test@example.xom',
  subscriptionEndDate: '2019-09-03T08:00:25.283Z',
  subscriptionStatus: SubscriptionStatus.Active,
});

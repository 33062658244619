import { ThingPendingChangeType } from './ThingPendingChangeType';

export interface ThingPendingChangeFields {
  type: ThingPendingChangeType;
  oldValue: unknown;
  newValue: unknown;
}

export class ThingPendingChange implements ThingPendingChangeFields {
  type: ThingPendingChangeType;
  oldValue: unknown;
  newValue: unknown;

  constructor({ type, oldValue, newValue }: ThingPendingChangeFields) {
    this.type = type;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}

/* eslint-disable @typescript-eslint/camelcase */
import { i18n } from '@/locales/i18n';
import DeviceSDK from 'aws-iot-device-sdk';
import { cloneDeep } from 'lodash-es';
import semver from 'semver';
import { isDefined } from '../../utils/Utils';
import { AuthService } from '../auth/AuthService';
import { ManifestService } from '../manifest/ManifestService';
import { SnackbarType } from '../snackbar/models/SnackbarType';
import { SnackbarService } from '../snackbar/SnackbarService';
import { PublishUpdateParameters } from './models/PublishUpdateParameters';
import { ThingPayload } from './models/ThingPayload';
import { ThingStateFull } from './models/ThingStateFull';
import { ThingsMQTTServiceInterface } from './ThingsMQTTServiceInterface';

class ThingsMQTTServiceImplementation implements ThingsMQTTServiceInterface {
  shadow?: DeviceSDK.thingShadow;
  private subscribedTopics: string[] = [];
  private readonly MAX_EVENT_LISTENERS = 64;

  async createShadow(
    forceCreateShadow = false
  ): Promise<DeviceSDK.thingShadow> {
    if (this.shadow && !forceCreateShadow) return this.shadow;

    // ? Disconnect old shadow before replacing it.
    if (forceCreateShadow) this.disconnect();

    const { cognitoCredentials } = AuthService.getCredentials();

    if (isDefined(cognitoCredentials.Credentials)) {
      const manifest = await ManifestService.getManifest();
      const clientId = Math.random()
        .toString()
        .substring(2, 10);

      this.shadow = new DeviceSDK.thingShadow({
        host: manifest.IotEndpointATS,
        region: manifest.Region,
        protocol: 'wss',
        clientId,
        accessKeyId: cognitoCredentials.Credentials?.AccessKeyId,
        secretKey: cognitoCredentials.Credentials?.SecretKey,
        sessionToken: cognitoCredentials.Credentials?.SessionToken,
      });
      this.shadow.setMaxListeners(this.MAX_EVENT_LISTENERS);
      this.onError(() => {
        SnackbarService.open(
          i18n.tc('snackbar.somethingWentWrong'),
          SnackbarType.Error
        );
      });

      return this.shadow;
    } else {
      throw new Error(
        'ThingsMQTTService is cannot be initialized due to missing credentials.'
      );
    }
  }

  async connect(callback: () => void): Promise<void> {
    await this.createShadow();

    this.shadow?.on('connect', () => {
      callback();
    });
  }

  onError(callback: (error: Error | string) => void): void {
    this.shadow?.on('error', callback);
  }

  onMessage(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (topic: string, payload: any, json?: ThingPayload) => void
  ): void {
    this.shadow?.on('message', (topic, payload) => {
      let json;

      if (
        payload instanceof Uint8Array ||
        payload instanceof Uint16Array ||
        payload instanceof Uint32Array
      ) {
        json = JSON.parse(payload.toString());
      }

      callback(topic, payload, json);
    });
  }

  async subscribeToUpdate(
    domainPath: string,
    thingId: string,
    forceCreateShadow = false
  ): Promise<void> {
    await this.createShadow(forceCreateShadow);

    const topic = `thing-update${domainPath}${thingId}`;
    this.shadow?.subscribe(topic);
    this.subscribedTopics.push(topic);
  }

  async subscribeToNetworkedThingUpdate(
    domainPath: string,
    thingId: string,
    networkedThingId: string,
    forceCreateShadow = false
  ): Promise<void> {
    await this.createShadow(forceCreateShadow);

    const topic = `thing-update${domainPath}${thingId}__${networkedThingId}`;
    this.shadow?.subscribe(topic);
    this.subscribedTopics.push(topic);
  }

  publishUpdate({
    domainPath,
    thingId,
    state,
    useReportedState = false,
  }: PublishUpdateParameters): void {
    const payload = this.createPayload(state, useReportedState);
    const topic = `thing-update${domainPath}${thingId}`;
    this.shadow?.publish(topic, JSON.stringify(payload));
  }

  forceThingUpdate(domainPath: string, thingId: string): void {
    this.publishUpdate({
      domainPath,
      thingId,
      state: { update_data: true } as ThingStateFull,
    });
  }

  disconnect(): void {
    const uniqueSubscribedTopics = [...new Set(this.subscribedTopics)];
    if (uniqueSubscribedTopics.length > 0) {
      this.shadow?.unsubscribe(uniqueSubscribedTopics);
      this.subscribedTopics = [];
    }
    this.shadow?.removeAllListeners();
    this.shadow?.end(false);
  }

  private normalizeSMSNumbers(state: ThingStateFull): void {
    const firmwareVersion = state.fw_version ?? state.firmware_version ?? '';
    if (!firmwareVersion) return;

    const isUsingNewFirmware = semver.gte(firmwareVersion, '2.2.3');
    if (isUsingNewFirmware) {
      if (state?.sms_number_1 === '') {
        state.sms_number_1 = 'EMPTY';
      }

      if (state?.sms_number_2 === '') {
        state.sms_number_2 = 'EMPTY';
      }
    }
  }

  createPayload(
    stateFull: ThingStateFull,
    useReportedState = false
  ): ThingPayload {
    const state = cloneDeep(stateFull);
    this.normalizeSMSNumbers(state);
    const stateKey = useReportedState ? 'reported' : 'desired';

    const payload: ThingPayload = {
      state: {
        [stateKey]: state,
      },
    };

    return payload;
  }
}

export const ThingsMQTTService = new ThingsMQTTServiceImplementation();

import { CacheServiceInterface } from './CacheServiceInterface';

class CacheServiceImplementation implements CacheServiceInterface {
  private cache: Record<string, Record<string, unknown>> = {};

  private ensureNamespaceExists(namespace: string): void {
    if (!this.cache[namespace]) {
      this.cache[namespace] = {};
    }
  }

  set<T>(namespace: string, key: string, value: T): void {
    this.ensureNamespaceExists(namespace);

    this.cache[namespace][key] = value;
  }

  get<T>(namespace: string, key: string): T | undefined {
    this.ensureNamespaceExists(namespace);
    const item = this.cache[namespace][key];
    return item as T | undefined;
  }

  clear(): void {
    this.cache = {};
  }
}

export const CacheService = new CacheServiceImplementation();

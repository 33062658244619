import { Optional } from '../utils/Optional';
import { isDefined, isString } from '../utils/Utils';

interface ValueWithUnitFields<VALUE = number, UNIT = string> {
  value?: Optional<VALUE>;
  unit?: Optional<UNIT>;
}

export class ValueWithUnit<VALUE = number, UNIT = string>
  implements ValueWithUnitFields<VALUE, UNIT> {
  value?: Optional<VALUE>;
  unit?: Optional<UNIT>;

  constructor({ value, unit }: ValueWithUnitFields<VALUE, UNIT>) {
    this.value = value;
    this.unit = unit;
  }

  get formattedUnit(): string | Optional<UNIT> {
    if (isDefined(this.unit) && isString(this.unit)) {
      let unit = this.unit.replace('3', '³');

      if (unit === 'C' || unit === 'K') {
        unit = `°${unit}`;
      }

      return unit;
    }
    return this.unit;
  }

  toString(): string {
    if (isDefined(this.value) && isDefined(this.unit) && isString(this.unit)) {
      return `${this.value} ${this.formattedUnit}`;
    }

    return '';
  }
}

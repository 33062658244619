import { AxiosInstance } from '../../api/axios';
import { BACKEND_REST_API_BASE_URL } from '../../config';
import { AuthService } from '../auth/AuthService';
import { ThingUnitAlarm } from '../things/models/ThingUnitAlarm';
import { AlarmsServiceInterface } from './AlarmsServiceInterface';
import { AlarmSetting } from './models/AlarmSetting';

class AlarmsServiceImplementation implements AlarmsServiceInterface {
  sort(alarms: ThingUnitAlarm[], ascending = false): ThingUnitAlarm[] {
    const alarmsToSort = [...alarms];

    alarmsToSort.sort((a, b) => {
      if (a.time < b.time) return 1;
      if (a.time > b.time) return -1;

      return 0;
    });

    if (ascending) {
      alarmsToSort.reverse();
    }

    return alarmsToSort;
  }

  getHighestPrioAlarm(alarms: ThingUnitAlarm[]): ThingUnitAlarm | undefined {
    const alarmsToSort = [...alarms];

    // ? Sort algorithm copied from old Angular app.
    alarmsToSort
      .sort((a, b) => {
        if (a.prio > b.prio) return 1;
        if (b.prio > a.prio) return -1;

        return 0;
      })
      .reverse();

    return alarmsToSort.pop();
  }

  async getAlarmSettings(
    thingId: string,
    unitId: string
  ): Promise<AlarmSetting[]> {
    try {
      const { data }: { data: AlarmSetting[] } = await AxiosInstance.get(
        `${BACKEND_REST_API_BASE_URL}/things/${thingId}/units/${unitId}/alarm-settings`,
        {
          baseURL: '',
          headers: {
            authorization: AuthService.getToken(),
            'x-auth-token': AuthService.getToken(),
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  }

  async updateAlarmSettings(
    thingId: string,
    unitId: string,
    alarms: AlarmSetting[]
  ): Promise<void> {
    await AxiosInstance.post(
      `${BACKEND_REST_API_BASE_URL}/things/${thingId}/units/${unitId}/alarm-settings`,
      alarms,
      {
        baseURL: '',
        headers: {
          authorization: AuthService.getToken(),
          'x-auth-token': AuthService.getToken(),
        },
      }
    );
  }
}

export const AlarmsService = new AlarmsServiceImplementation();
